import { ScrollToParamTypes } from './utils.types';

function scrollTo({ target, offsetY = 0, duration = 2 }: ScrollToParamTypes) {
  return () => {
    window?.gsap?.to(window, {
      duration,
      scrollTo: {
        y: `[data-id='${target?.slice(1)}']`,
        offsetY,
      },
    });
  };
}

export default scrollTo;
