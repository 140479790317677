import React from 'react';
import { ExpanderPropTypes } from './Expander.types';
const Expander: React.FC<ExpanderPropTypes> = ({
  className = '',
  children
}) => {
  return <div className={`w-screen h-[100%] mx-auto ml-[-2.4rem] md:ml-[-6.3rem] lg:ml-[calc((-100vw+100%)/2)] mr-[calc((-100vw+100%)/2)] ${className}`} data-sentry-component="Expander" data-sentry-source-file="Expander.tsx">
      {children}
    </div>;
};
export default Expander;